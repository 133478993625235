import React from 'react';

import logo from '../images/halatech.svg';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuIsOpen: true,
      menuToggle: false,
      isTop: true
    };

    this.handleMenuToggle = this.handleMenuToggle.bind(this);
  }
  componentDidMount() {
    document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 100;
      if (isTop !== this.state.isTop) {
        this.setState({ isTop });
      }
    });
  }
  handleMenuToggle() {
    this.setState({
      menuIsOpen: !this.state.menuIsOpen,
      menuToggle: !this.state.menuToggle
    });
  }

  render() {
    return (
      <div className="navigation">
        <div className="container-fluid">
          <nav className="nav">
            <div className="nav__left">
              <img src={logo} alt="Logo" className="logo" width="40%" />
            </div>
            <div className={`hamburger ${this.state.menuIsOpen ? ' ' : 'is-open'}`} onClick={this.handleMenuToggle}>
              <div className="hamburger__toggle" />
            </div>
            <div className={`nav__right ${this.state.menuToggle ? ' ' : 'hide-nav'}`}>
              <ul className="nav__menu">
                {/* <li className="nav__item"> */}
                {/* <Link
										to="business"
										className="nav__link"
										activeClass="active"
										spy={true}
										smooth={true}
										offset={-70}
										duration={500}
									>
										Business
									</Link> */}
                {/* <a
                    href="https://hala-tech.gogecko.com/users/request-access"
                    className="nav__link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Business
									</a>
                </li> */}
                <li className="nav__item">
                  <a
                    href="https://www.ebay.com/str/halatech"
                    className="nav__link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Find a Used Phone
									</a>
                </li>
                <li className="nav__item">
                  <a
                    href="https://hala-tech.gogecko.com/users/login"
                    className="nav__link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Wholesale Partners
									</a>
                </li>
                <li className="nav__item">
                  <button className="nav__link">Join our network</button>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    );
  }
}

export default Header;
