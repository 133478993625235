import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import App from '../App';
import PrivacyPolicy from './Privacy';

class SubRouter extends Component {
  render() {
    const { subdomain } = this.props;
    console.log(subdomain)
    if (subdomain === 'privacy') {
      return <PrivacyPolicy/>
    } else {
      return <App />;
    }
  }
}

export default withRouter(SubRouter);
