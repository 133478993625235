import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import './index.css';
import SubRouter from "./components/SubRouter";

const subdomain = window.location.host.split('.')[0];

ReactDOM.render(
  <BrowserRouter basename=".">
    <Route path="/" render={() => <SubRouter subdomain={subdomain} />} />
  </BrowserRouter>,
  document.getElementById('root')
);
