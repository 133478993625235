import React from 'react';

import logoWhite from '../images/logowhite.svg';

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <div className="cta" id="cta">
          <h2>Want to partner with us?</h2>
          <p>
            Join us as we partake in the circular economy.
            </p>
          <button className="btn-secondary">Chat with Us</button>
        </div>
        <div className="footer__links">
          <div className={`container-fluid`}>
            <div className="row">
              <div className="col-md-3">
                <img src={logoWhite} alt="logowhite" width="30%" />
              </div>
              <div className="col-md-3">
                <h6>How Can We Help?</h6>
                <ul>
                  <li>
                    <a href="#problem">Business</a>
                  </li>
                  <li>
                    <a href="#opportunity">Buy Phones</a>
                  </li>
                  <li>
                    <a href="#solution">Partner with us</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-3">
                <h6>Company</h6>
                <ul>
                  <li>
                    <a href="#team">About Us</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-3">
                <h6>Office</h6>
                <ul>
                  <li>
                    <address>
                      4625 Lindell Blvd, Suite 200, St. Louis, MO
                    </address>
                  </li>
                  <li>
                    <a href="mailto:info@halatech.io">
                      info@halatech.io
                    </a>
                  </li>
                  <li>
                    <a href="tel:1-314-437-1212">+1-314-437-1212</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
