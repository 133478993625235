import React from 'react';
import Header from './components/_Header';
import Footer from './components/Footer';


import businessphone from './images/phone-business.jpg';
import partnerphone from './images/phone-partner.jpg'
import enviromentIcon from './images/environment-icon.svg';
import profitIcon from './images/profit-icon.svg';
import financeIcon from './images/finance-icon.svg';
import gradingIcon from './images/grading.svg'
import inventoryIcon from './images/inventory.svg';
import fastIcon from './images/fast.svg';
import globe from './images/phoneimages.png';

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <Header />
        <div className={`row hero__header`}>
          <div className={`col-md-6 hero__header-text`}>
            <h1>
              Buy <span>premium used devices</span> in bulk at the <span>best</span> prices
            </h1>
            <p>
              At Hala Tech, we are collecting directly from the source; the consumer. We use an advanced intelligence
              dashboard that helps us determine the least possible buy back value for these used phones backed by real
              time data and analysis of the market.
            </p>
            <button className="btn-primary">Buy Now</button>
          </div>
          <div className={`section__image col-md-6`}>
            <img className="img" src={globe} alt="Circular" width="65%" style={{ display: 'block', margin: 'auto' }} />
          </div>
        </div>
        <section className={`how container-fluid`}>
          <div className="row">
            <div className="col-lg-4 benefits__item">
              <img className="icon" src={gradingIcon} alt="Icon for the Environment" />
              <h3>Accurate Grading</h3>
              <p>All devices undergo a stringent testing and grading process, ensuring consistent quality</p>
            </div>
            <div className="col-lg-4 benefits__item">
              <img className="icon" src={inventoryIcon} alt="Icon for the Environment" />
              <h3>Consistent Inventory</h3>
              <p>
                We source our inventory of wholesale cell phones directly from carriers and manufacturers to ensure
                consistent supply.
              </p>
            </div>
            <div className="col-lg-4 benefits__item">
              <img className="icon" src={fastIcon} alt="Icon for the Environment" />
              <h3>Fast Turnaround</h3>
              <p>
                Use our self-service platform to find the wholesale cell phones you want, and we will ship them out in
                as little as 24 hours
              </p>
            </div>
          </div>
        </section>
        <section className={`problem container-fluid`} id="business">
          <div className="row middle-md">
            <div className={`section__image col-md-6`}>
              <img className="img" src={businessphone} alt="Problem" width="100%" />
            </div>
            <div className={`col-md-6 section__text`}>
              <hr />
              <h6>FOR BUSINESSES</h6>
              <h2>Our Inventory Comes Direct from the Source</h2>
              <p>
                At Hala Tech, we believe that the used smartphone market is a relatively untapped goldmine. We acquire
                devices from the very grassroot layer of the used device landscape. We are collecting directly from the
                source; the consumer. This allows us buy devices at the lowest prices possible. This means that we sell
                to you at the best possible price you can ever get in the market.
              </p>
              <p>
                We not only offer you the best prices but also, the best quality used devices in the market. All
                Halatech devices have been properly inspected for quality, reliability and functionality.
              </p>
              <button className="btn-primary">Buy your devices</button>
            </div>
          </div>
        </section>
        <section className={`solution container-fluid`} id="partner">
          <div className="row middle-md">
            <div className={`col-md-6 section__text`}>
              <hr />
              <h6>For Partners</h6>
              <h2>
                The global market for refurbished smartphones grew 13% YoY in 2017, reaching close to 140 million units.
              </h2>
              <p>
                At Hala Tech, we believe that the used smartphone market is a relatively untapped goldmine. We acquire
                devices from the very grassroot layer of the used device landscape. We are collecting directly from the
                source; the consumer. This allows us buy devices at the lowest prices possible. Our intelligence
                software is optimized to focus on unit profitability, where every device transaction should be
                profitable from inception. Any operational management should be codified into the software that runs the
                system. Essentially, participants are led by intuitive software and incentivized by monetary rewards to
                make the right decisions, instead of rigorous management and expensive oversight.
              </p>
            </div>
            <div className={`col-md-6`}>
              <img className="img" src={partnerphone} alt="Partner Phone" width="100%"/>
            </div>
          </div>
          <div className="benefits">
            <div className="row">
              <div className="col-lg-4 benefits__item">
                <img className="icon" src={enviromentIcon} alt="Icon for the Environment" />
                <h3>Environmental Impact</h3>
                <p>
                  The longer devices are kept in circulation, the longer it takes to get into a landfill, thereby
                  reducing hazardous emissions of harmful c hemicals into our soils. When devices are dropped in the
                  landfills, they decompose or rot and release harmful chemicals into the air or the surrounding waters.
                  Selling used phones keeps the phones in circulation thereby reducing this risks.
                </p>
              </div>
              <div className="col-lg-4 benefits__item">
                <img className="icon" src={financeIcon} alt="Icon for the Environment" />
                <h3>Financial Value</h3>
                <p>
                  Consumers selling their phones get to exchange it for cash or credit towards getting a new phone.
                  Buying consumers can purchase high valued phones for the least possible prices available.
                </p>
              </div>
              <div className="col-lg-4 benefits__item">
                <img className="icon" src={profitIcon} alt="Icon for the Environment" />
                <h3>Profitability</h3>
                <p>
                  We benefit from selling acquired devices bought at the least possible price and resold at an higher
                  cost. Our intelligence software is optimized to focus on unit profitability, where every device
                  transaction should be profitable from inception.
                </p>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default App;
